<template>
  <div style="width: 100%;">
    <page-main back title>
      <template slot="top">
        <Steps
          :value="3"
          :list="[
            `1.新建${sceneRuleChinese}`,
            '2.设置任务规则',
            '3.发布委托需求',
            '4.选择任务对象并分配'
          ]"
        />
      </template>
      <div class="scene-item">
        <div class="font-weight mgn-b10">
          【委托服务指南】：
        </div>
        ① 委托平台调研/病例实战：若您希望平台协助发起调研/病例实战，可直接选择现有问卷/病例或新建，并点击“下一步”继续。<br>
        ② 自主学术调研/病例实战：若调研/病例实战对您的学术推广有帮助，但您想自己发起，可选一份问卷后点“跳过”开始。<br>
        ③ 内容推广：若您仅需进行学术信息传递，无需观念调研/测评，可以不选择调研/病例实战，直接点击底部“跳过”。
      </div>
      <div class="form-item">
        <el-row v-if="accountInfo.relationWithProject" type="flex">
          <span>扣款账户：</span>
          <el-radio-group v-model="projectTargetId" class="account">
            <el-radio :label="accountInfo.projectTargetId">{{ accountInfo.projectName }}：{{ accountInfo.projectRemainBudget }}</el-radio>
            <el-radio label="">我的账户：{{ accountInfo.orgAccount }}</el-radio>
          </el-radio-group>
        </el-row>
        <el-tabs v-model="activityQuestType" @tab-click="changeActivity">
          <el-row type="flex" justify="space-between" align="middle">
            <div class="search-item">
              <el-select
                v-if="activityQuestType === '2'"
                v-model="isQuerySelf"
                placeholder="选择来源"
                style="width: 120px;"
                @change="changeActivity"
              >
                <el-option label="我的" :value="true" />
                <el-option label="其他组织" :value="false" />
              </el-select>
              <el-select
                v-if="activityQuestType === '2'"
                v-model="publishStatus"
                placeholder="发布状态"
                style="width: 120px;"
                @change="changeActivity"
              >
                <el-option label="已发布" :value="1" />
                <el-option label="草稿" :value="0" />
              </el-select>
              <el-input
                v-if="activityQuestType === '2'"
                v-model="searchKey"
                v-debounce="[getEntrustList, 'input']"
                prefix-icon="el-icon-search"
                placeholder="搜索名称/作者"
                class="search-input"
                clearable
                @keydown.enter.native="getEntrustList"
              />
            </div>
            <el-button
              v-if="sceneType !== 'questionnaire'"
              type="primary"
              plain
              @click="addEntrust"
            >
              + 新建{{ activityQuestType === '2' ? '病例实战' : '调研问卷' }}
            </el-button>
          </el-row>
          <el-tab-pane v-if="sceneType !== 'questionnaire'" label="病例实战" name="2">
            <div>
              <el-table
                ref="refsTable"
                :data="entrustList"
                tooltip-effect="dark"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
              >
                <div slot="empty">
                  暂无病例实战，<el-button type="text" @click="addCasePractice">前往创建！</el-button>
                </div>
                <el-table-column
                  v-if="sceneType !== 'questionnaire' && publishStatus === 1"
                  type="selection"
                  width="55"
                />
                <el-table-column
                  label="病例实战名称"
                  prop="title"
                  center
                />
                <el-table-column
                  prop="author"
                  label="作者"
                  center
                />
                <el-table-column
                  prop="productTagName"
                  label="产品"
                  center
                />
                <el-table-column
                  prop="totalExamCount"
                  label="题目数"
                  center
                />
                <el-table-column
                  prop="name"
                  label="已回收/总数"
                  center
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.completedQuestNum }}/{{ scope.row.totalQuestNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdAt"
                  label="创建时间"
                  center
                />
                <el-table-column
                  prop="orgName"
                  label="创建组织"
                  center
                />
                <el-table-column
                  prop="address"
                  label="操作"
                  center
                >
                  <template slot-scope="scope">
                    <el-button type="text" @click="onPreView(scope.row)">预览</el-button>
                    <el-button
                      v-if="scope.row.editable"
                      type="text"
                      @click="editCasePractice(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-button
                      v-if="scope.row.deleteAble"
                      type="text"
                      style="color: rgb(232, 49, 47);"
                      @click="delExam(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                hide-on-single-page
                layout="prev, pager, next"
                :page-size="pageSize"
                :current-page="pageNo"
                :total="totalCount"
                @current-change="onChangePage"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="调研问卷" name="1">
            <div>
              <el-table
                ref="refsTable"
                :data="entrustList"
                tooltip-effect="dark"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
              >
                <!-- 空数据 -->
                <div slot="empty">
                  暂无调研问卷，<el-button type="text" @click="addEntrust">前往创建！</el-button>
                </div>
                <el-table-column
                  v-if="sceneType !== 'questionnaire'"
                  type="selection"
                  width="55"
                />
                <el-table-column
                  label="调研问卷名称"
                  prop="title"
                  center
                />
                <el-table-column
                  prop="examType"
                  label="题目设置"
                  center
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.examType === 0">分对错</span>
                    <span v-else>不分对错</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="answerType"
                  label="答题方式"
                  center
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.answerType === 1">随机答题</span>
                    <span v-else>顺序答题</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="totalExamCount"
                  label="题目数"
                  center
                >
                  <template slot-scope="{row}">
                    <template v-if="row.answerType === 1">
                      共<span>{{ row.totalExamCount }}</span>题，随机抽取<span>{{ row.randomExamCount }}</span>题
                    </template>
                    <template v-else>
                      {{ row.totalExamCount }}题
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="已回收问卷/总问卷"
                  center
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.completedQuestNum }}/{{ scope.row.totalQuestNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdAt"
                  label="创建时间"
                  center
                />
                <el-table-column
                  prop="orgName"
                  label="创建组织"
                  center
                />
                <el-table-column
                  prop="address"
                  label="操作"
                  center
                >
                  <template slot-scope="scope">
                    <el-button type="text" @click="viewExam(scope.row)">预览</el-button>
                    <el-button
                      v-if="scope.row.editable"
                      type="text"
                      @click="editExam(scope.row)"
                    >
                      编辑
                    </el-button>
                    <el-button
                      v-if="scope.row.deleteAble"
                      type="text"
                      style="color: rgb(232, 49, 47);"
                      @click="delExam(scope.row)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </page-main>
    <fixed-action-bar>
      <el-button
        v-if="!sceneAuditRecordId"
        plain
        type="primary"
        style="width: 90px;"
        @click="onBackLast"
      >
        上一步
      </el-button>
      <el-button
        v-if="!sceneAuditRecordId"
        plain
        type="primary"
        style="width: 90px;"
        @click="onJumpOut"
      >
        跳过
      </el-button>
      <el-button
        v-if="!!sceneAuditRecordId"
        type="primary"
        :loading="isSubmit"
        @click="onSubmit('2')"
      >
        通过并提交审核
      </el-button>
      <el-button
        v-else
        type="primary"
        style="width: 90px;"
        :loading="isSubmit"
        @click="onSubmit('1')"
      >
        下一步
      </el-button>
    </fixed-action-bar>
    <AddEntrustDialog
      v-if="showAddEntrustDialog"
      :visible="showAddEntrustDialog"
      :is-edit="isEdit"
      :activity-quest-id="editId"
      :scene-id="sceneId"
      @close="closeAddEntrustDialog"
      @submit="saveEntrust"
    />
    <ViewEntrustDialog
      v-if="viewEntrustDialog"
      :visible="viewEntrustDialog"
      :entrust-id="entrustId"
      :scene-id="sceneId"
      @close="viewEntrustDialog = false"
    />
    <CommonPreviewModal
      v-model="showPreviewModal"
      :preview-url="previewUrl"
    />
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import {inviteCodeType} from '@/utils/util'
import domainRoute from '@/utils/domain-route'
import AddEntrustDialog from './components/AddEntrustDialog.vue'
import ViewEntrustDialog from './components/ViewEntrustDialog.vue'
import CommonPreviewModal from '@/bizComponents/CommonPreviewModal.vue'
export default {
  name: 'AcademicEntrust',
  components: {
    AddEntrustDialog,
    ViewEntrustDialog,
    CommonPreviewModal
  },
  data() {
    return {
      showPreviewModal: false,
      previewUrl: '',
      editId: '',
      sceneTitle: '',
      sceneType: '', // 'meeting(1, "科会", "meeting"), survey(2, "调研", "survey"), visit(3, "拜访", "old_visit"), questionnaire(4, "问卷调研", "questionnaire"), live(5, "定向直播", "live"), webinar(6, "多人会议")	'
      sceneId: '',
      disabledBack: false,
      ruleId: '',
      projectType: '',
      isSubmit: false,
      title: '',
      remark: '',
      exams: [],
      answerType: null,
      advertActivityId: '',
      examType: null,
      delegationPackageId: '',
      randomExamCount: null,
      sceneAuditRecordId: '',
      typeObj: {meeting: 1, questionnaire: 4, live: 5, webinar: 6, visit: 8, MEDICAL_PODCAST: 12},
      projectTargetId: '',
      accountInfo: {},
      entrustList: [],
      activityQuestId: '',
      viewEntrustDialog: false,
      entrustId: '',
      showAddEntrustDialog: false,
      tabList: [
        {
          label: '病例实战',
          name: '2'
        },
        {
          label: '调研问卷',
          name: '1'
        }
      ],
      activityQuestType: '2',
      searchKey: '',
      isQuerySelf: true,
      publishStatus: 1,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  computed: {
    sceneRuleChinese() {
      switch (this.sceneType) {
        case 'meeting':
          return '科会'
        case 'questionnaire':
          return '调研/病例'
        case 'live':
          return '直播'
        case 'webinar':
          return '会议'
        case 'academicVisit':
          return '拜访'
        case 'training':
          return 7
        case 'MEDICAL_PODCAST':
          return 12
        default:
          return ''
      }
    },
    sceneTypeStr() {
      switch (this.sceneType) {
        case 'meeting':
        case 'MEDICAL_PODCAST':
          return 'meeting'
        case 'live':
        case 'webinar':
          return 'live'
        default:
          return ''
      }
    }
  },
  mounted() {
    this.init()
    if (this.sceneRuleChinese === '拜访') {
      this.$router.replace(this.nextStepUrlOpt('0'))
    } else {
      this.getProjectInfo()
    }
  },
  methods: {
    onPreView(row) {
      this.$axios.get(this.$API.getPreViewToken).then(res => {
        this.previewUrl = domainRoute.casePracticePreview(row.activityQuestId, this.sceneTypeStr, res.data)
        this.showPreviewModal = true
      })
    },
    init() {
      this.disabledBack = this.$route.query.disabledBack || false
      this.projectType = this.$route.query.projectType || ''
      this.sceneType = this.$route.params.sceneType || ''
      this.sceneId = this.$route.params.sceneId || ''
      this.ruleId = this.$route.query.ruleId || ''
      this.advertActivityId = this.$route.query.advertActivityId || ''
      this.sceneAuditRecordId = this.$route.query.sceneAuditRecordId || ''
      if (this.$route.params.sceneType === 'questionnaire') {
        this.activityQuestType = '1'
      } else {
        this.activityQuestType = '2'
      }
      this.getEntrustList()
    },
    async getProjectInfo() {
      try {
        const params = {
          sceneId: this.sceneId,
          sceneType: this.typeObj[this.sceneType]
        }
        const {data} = await this.$axios.get(this.$API.activityProjectRelation, {params})
        if (data.relationWithProject) {
          this.projectTargetId = data.projectTargetId
        }
        this.accountInfo = data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    onBackLast() {
      this.$router.go(-1)
    },
    changeActivity() {
      this.pageNo = 1
      this.entrustList = []
      this.getEntrustList()
    },
    onChangePage(pageNo) {
      this.pageNo = pageNo
      this.getEntrustList()
    },
    getEntrustList() {
      const data = {
        sceneId: this.sceneId,
        activityQuestType: this.activityQuestType,
        searchKey: this.searchKey,
        isQuerySelf: this.activityQuestType === '1' ? null : this.isQuerySelf,
        publishStatus: this.publishStatus,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      this.$axios.post(this.$API.historyDelegationList, data).then(res => {
        this.entrustList = res.data.data
        this.totalCount = res.data.totalCount
        if (this.sceneType === 'questionnaire') {
          this.activityQuestId = this.entrustList[0].activityQuestId
        }
      })
    },
    onSubmit(type) {
      if (!this.activityQuestId) {
        this.$message.error('请选择调研问卷')
        return
      }
      this.onNext(type)
    },
    onJumpOut() {
      this.$confirm('请确认本次任务分配细节：您是否决定不通过委托平台发起调研', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.onNext('0')
      }).catch(() => {
      })
    },
    onNext(type) {
      if (type === '2') {
        this.onSubmitSceneAudit()
      } else {
        if (this.activityQuestId) {
          this.$axios
            .post(this.$API.saveDelegationForm, {
              sceneId: this.sceneId,
              sceneType: inviteCodeType(this.sceneType)[0],
              activityQuestId: this.activityQuestId,
              includeDelegation: type !== '0',
              projectTargetId: this.projectTargetId,
              updateOrSaveQuest: false,  // true: 新建和编辑，false：跳过和下一步
              activityQuestType: this.activityQuestType
            })
            .then(() => {
              this.nextStepUrl(type)
            }, rea => {
              this.$message.error(rea.message)
            })
        } else {
          this.nextStepUrl(type)
        }
      }
    },
    nextStepUrlOpt(type) {
      return {
        path: `/academic/scene/${this.sceneType}/allot/${this.sceneId}`,
        query: {
          ruleId: this.ruleId,
          projectType: this.projectType,
          disabledBack: this.disabledBack,
          advertActivityId: this.advertActivityId,
          includeDelegation: type !== '0',
          activityQuestId: this.activityQuestId,
          isExistingCodeAssign: false
        }
      }
    },
    nextStepUrl(type) {
      this.$router.push(this.nextStepUrlOpt(type))
    },
    onSubmitSceneAudit() {
      this.$confirm('请确认是否通过并提交审核', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isSubmit = true
        this.$axios
          .get(this.$API.sceneAuditDetail, {
            params: { sceneAuditRecordId: this.sceneAuditRecordId }
          })
          .then(
            res => {
              const data = {
                sceneAuditRecordId: res.data.sceneAuditRecordId,
                sceneTaskMemberBOS: res.data.sceneTaskMemberVOS,
                sceneTaskRuleBOS: res.data.sceneTaskRuleVOS,
                activityQuestId: this.activityQuestId,
                isAuditSuccess: true,
                includeDelegation: true
              }
              this.$axios.post(this.$API.passAssignSceneTaskApply, data).then(
                () => {
                  this.isSubmit = false
                  this.$message.success('提交审核成功')
                  this.$EventBus.$emit('getList')
                  this.$router.push('/approver/list')
                },
                rea => {
                  this.isSubmit = false
                  this.$message.error(rea.message)
                }
              )
            },
            rea => {
              this.$message.error(rea.message)
              this.isSubmit = false
            }
          )
      })
    },
    handleSelectionChange(selection) {
      if (selection.length === 0) {
        this.activityQuestId = ''
        return
      }
      if (selection.length > 1) {
        // 移除上一次选中行数据
        selection.shift()
        // 修改选中图标为未选中状态
        this.$refs.refsTable.clearSelection()
        // 将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0])
      }
      if (selection.length) {
        this.activityQuestId = selection[0].activityQuestId
      }
    },

    viewExam(row) {
      this.isEdit = false
      this.editId = ''
      this.viewEntrustDialog = true
      this.entrustId = row.activityQuestId
    },
    addEntrust() {
      if (this.activityQuestType === '2') {
        this.addCasePractice()
        return
      }
      this.isEdit = false
      this.editId = ''
      this.showAddEntrustDialog = true
    },
    addCasePractice() {
      this.$router.push({
        name: 'CasePracticeDetail',
        params: {
          id: this.sceneId
        },
        query: {
          sceneType: inviteCodeType(this.sceneType)[0]
        }
      })
    },
    editCasePractice(row) {
      this.$router.push({
        name: 'CasePracticeDetail',
        params: {
          id: this.sceneId
        },
        query: {
          activityQuestId: row.activityQuestId,
          sceneType: inviteCodeType(this.sceneType)[0]
        }
      })
    },
    editExam(row) {
      this.isEdit = true
      this.showAddEntrustDialog = true
      this.editId = row.activityQuestId
    },
    delExam(row) {
      MessageBox.confirm(`请确认是否需要删除该${this.activityQuestType === '2' ? '病例实战' : '调研问卷' }，一经删除不可恢复?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          this.$axios.get(this.$API.putQuestInTrash, { params: { activityQuestId: row.activityQuestId } }).then(() => {
            this.$message.success('删除成功')
            this.getEntrustList()
          })
        } catch (error) {
          console.log(error)
          this.$message.error(error)
        }
      })
    },

    closeAddEntrustDialog() {
      this.showAddEntrustDialog = false
      this.getEntrustList()
    },
    saveEntrust(data) {
      this.onSaveEntrust(data)
    },
    onSaveEntrust(data) {
      this.$axios
        .post(this.$API.saveDelegationForm, {
          sceneId: this.sceneId,
          sceneType: inviteCodeType(this.sceneType)[0],
          includeDelegation: false,
          ...data,
          updateOrSaveQuest: true,  // true: 新建和编辑，false：跳过和下一步
          activityQuestType: this.activityQuestType
        })
        .then(() => {
          this.$message.success(this.isEdit ? '编辑成功' : '创建成功')
          this.showAddEntrustDialog = false
          this.getEntrustList()
        }, rea => {
          this.$message.error(rea.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.scene-item {
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #e9eaeb;
  margin-left: 30px;
  color: #5a5a5a;
}
.scene-label {
  color: #272e40;
  font-weight: bold;
  flex-shrink: 0;
  min-width: 100px;
  text-align: right;
}
.form-item {
  margin-left: 30px;
  margin-bottom: 30px;
  .el-row {
    align-items: baseline;
  }
  div {
    margin-bottom: 10px;
    color: #5a5a5a;
  }
}
.scene-form-input ::v-deep {
  flex: 1;
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 0;
    border-color: #e9eaeb;
    color: #272e40;
    font-size: 14px;
    padding-left: 25px;
  }
  .el-textarea__inner {
    border-radius: 0;
    border-color: #e9eaeb;
    color: #272e40;
    font-size: 14px;
    padding-top: 15px;
    padding-left: 25px;
  }
  .el-input__count {
    font-size: 14px;
    color: #c8c9ce;
  }
}
.account {
  ::v-deep.el-radio__label {
    font-size: 12px;
  }
}
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
.search-input {
  width: 200px;
  margin-right: 10px;
  ::v-deep .el-input__inner {
    border-radius: 20px;
  }
}
.search-item {
  // 每个子元素之间的间距
  // :nth-child(1) {
  //   margin-right: 10px;
  // }
  // :nth-child(2) {
  //   margin-right: 10px;
  // }
  .el-select {
    width: 120px;
    margin-right: 10px;
  }
}
</style>
